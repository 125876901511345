<template>
  <section>
    <div class="pageBradcrumbs">
      <div class="containerLarge">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb small">
            <li class="breadcrumb-item">
              <b-button @click="toGlobal('/marine', 'marine')">Home</b-button>
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="banner">
      <div
        class="bannerImage"
        :style="{
          'background-image':
            'url(' + require('../assets/img/banner/slide-01.jpg') + ')',
        }"
      >
        <div class="containerLarge">
          <div class="pageTitle-banner text-center">
            <div class="pageTitle-content">
              <h1 class="mt-4">Products</h1>
              <div class="divider"></div>
              <p class="lead">
                Alfa Laval is a world leader within the key technology areas of heat transfer, separation and fluid handling. Our company was founded on a single brilliant invention and innovation remains at the heart of everything we do.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cardList mt-5 mt-md-5 mt-lg-5 pt-lg-5">
      <div class="containerLarge">
        <div class="row">
 
          <div v-for="el in elements" :key="el._id"
            class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 
            mb-2 mb-md-4 d-flex align-items-stretch" @click="toCategory(el._id)">
            <div class="cardBox">
              <div class="cardCategory">
                
                  <div class="row">
                    <div class="col-4 col-sm-12 text-center">
                      <img
                        class="img-fluid"
                        :src="getImage(el.imgproducts)"
                        alt=""
                      />
                    </div>
                    <div class="col-8 col-sm-12 pl-0">
                      <div class="card-body text-left text-sm-center">
                        <h3 class="card-title">{{el.shortname}}</h3>
                        <p>{{el.name}}</p>
                      </div>
                    </div>
                  </div>
         
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'Category',
  data: () => ({
    type: 'prodotto',
    name: '',
    file: '',
    link: false,
    showModal: false,
    elements: []
  }),
  created(){
    this.getCategories();
  },

  computed: {
    getCurrentPath(){
      return this.$store.getters.getCurrentPath;
    }
  },

  methods: {

    toGlobal(str, current){
      if (str !== this.getCurrentPath){
        this.$store.commit('setCurrentGlobal', current);
        this.$store.commit('setCurrentPath', str);
        this.$router.push(str);
      }      
    },
    
    getImage(imgproducts) {
      const bucket = process.env.VUE_APP_CLOUDINARY_BUCKET;
      return imgproducts
        ? process.env.VUE_APP_CLOUDINARY_BASE + imgproducts            
        : 'https://res.cloudinary.com/plurimedia/image/upload/v1495121472/plurimedia/placeholder.png';
    },

    toCategory(id){
      this.$store.commit('setCurrentGlobal', 'marine');
      const withId = '/category/' + id;
      this.$store.commit('setCurrentPath', withId);
      this.$router.push(withId);
    },

    getCategories(){
      this.$http.get('/api/sito/categoria/all')
        .then((result) => {
          this.elements = result.data;
        })
        .catch((err) => console.log('Problem on db: getCategories', err));
    }
  }
};
</script>
